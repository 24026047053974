<template>
    <div>
        <div class="position-relative">
            <div v-if="showColor" class="position-absolute p1">
                <v-swatches
                    v-model="colorRef"
                    popover-x="left"
                    swatches="text-advanced"
                    inline
                    @input="changeColor"
                >
                </v-swatches>
            </div>
        </div>
        <vs-input
            v-model="color"
            border
            :state="stateEnabled"
            shadow
            label-placeholder="Color título"
            icon-after
            :disabled="!enabled"
            @blur="changeColor"
            @input="lowerColor"
            @click-icon="
                () => {
                    if (enabled) {
                        showColor = !showColor;
                    }
                }
            "
        >
            <template #icon>
                <span
                    class="rounded"
                    :style="{
                        'background-color': colorRef
                    }"
                >
                    🎨
                </span>
            </template>
        </vs-input>
    </div>
</template>

<script>
import VSwatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css";
import { mapGetters } from "vuex";

export default {
    name: "ColorInput",
    components: {
        VSwatches
    },
    model: {
        prop: "itemCurrent",
        event: "change"
    },
    props: {
        itemCurrent: {
            default: () => ({ color: "blanco", colorRef: "#fff" }),
            type: Object,
            require: true
        },
        enabled: {
            default: () => true,
            type: Boolean,
            required: false
        }
    },
    emits: ["change"],
    data: () => ({
        color: "blanco",
        colorRef: "#fff",
        showColor: false
    }),
    computed: {
        ...mapGetters("control", ["stateInputDark"]),
        stateEnabled() {
            return this.enabled ? this.stateInputDark : "";
        }
    },
    watch: {
        itemCurrent(value) {
            this.setValue(value);
        }
    },
    mounted() {
        this.setValue(this.itemCurrent);
    },
    methods: {
        lowerColor() {
            this.color = this.color.toLowerCase();
        },
        hideColor() {
            this.showColor = false;
        },
        changeColor() {
            this.$emit("change", {
                color: this.color,
                colorRef: this.colorRef
            });
        },
        setValue(value) {
            this.color = value.color;
            this.colorRef = value.colorRef;
        }
    }
};
</script>

<style scoped>
.p1 {
    top: -12.5rem;
    right: 0;
    z-index: 20000;
}
</style>
